import styled from '@emotion/styled'
import Link from 'next/link'

import { breakpoint } from '@/theme'
import ROUTES from '@helpers/routes'
import { CityPage } from '@stories/city-page/types'
import { TEST_IDS } from '@stories/top-cities-list/constants'

export type PropTypes = {
  topCities: CityPage[][]
  maxWidth?: string
}

function TopCitiesList(props: PropTypes): JSX.Element {
  const { topCities, maxWidth } = props

  if (!topCities.length) return null

  return (
    <Container maxWidth={maxWidth}>
      <Header data-e2eid={TEST_IDS.HEADER}>Top Cities</Header>
      <CityGrid>
        {topCities.map((topCitiesColumn, colIndex) => (
          <Column key={colIndex}>
            {topCitiesColumn.map((city) => (
              <CityGridItem key={city.slug}>
                <Link href={{ pathname: ROUTES.CITY_PAGE, query: { slug: city.slug } }} passHref>
                  {/* when using custom classNames or data-e2eid on a next link, you need to use the a tag with a passHref on the Link component for SEO reasons */}
                  <a data-e2eid={TEST_IDS.CITY_LINK} className="inverse">
                    {city.city}
                  </a>
                </Link>
              </CityGridItem>
            ))}
          </Column>
        ))}
      </CityGrid>
      <SeeAllContainer>
        <Link href={ROUTES.WEED_DELIVERY} passHref>
          <a data-e2eid={TEST_IDS.SEE_ALL_LINK}>See All Cities</a>
        </Link>
      </SeeAllContainer>
    </Container>
  )
}

const Container = styled.section<{ maxWidth?: string }>`
  margin: 40px auto;
  max-width: ${({ maxWidth }) => maxWidth || `${breakpoint.min.xl}px`};
  padding: 0 20px;
`

const Header = styled.h2`
  font-size: ${({ theme }) => theme.typography.size.desktop.title2}px;
`

const CityGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.medium}px;

  @media (max-width: ${breakpoint.max.md}px) {
    display: block;
  }
`

const Column = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoint.max.md}px) {
    display: contents; // Allows cities to flow like one long list
  }
`

const CityGridItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing.smaller}px;
`

const SeeAllContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.medium}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;

  @media (max-width: ${breakpoint.max.md}px) {
    justify-content: flex-start;
  }
`

export default TopCitiesList
